import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"

import WordPressWysiwyg from "../components/WordPressWysiwyg"
import SocialShareButtons from "../components/SocialShareButtons"
import RelatedPosts from "../components/RelatedPosts"
import {
  standardWrapper,
  headlineTwo,
  colors,
  headlineThree,
} from "../Utilities"

const PostStyled = styled.article`
  .post-wrapper {
    ${standardWrapper};
  }

  .post-featured-img {
    width: 100%;
  }

  .post-title {
    margin-top: 5rem;

    h1 {
      ${headlineTwo};
      margin-bottom: 1rem;
      width: 100%;
      color: ${colors.colorSecondary};
      text-align: center;
      text-transform: uppercase;
    }
  }

  .post-meta {
    width: 100%;

    p {
      ${headlineThree};
      margin-top: 0;
      width: 100%;
      color: ${colors.colorAccent};
      text-align: center;
      text-transform: uppercase;
    }
  }
`

const post = props => {
  const post = props.data.post
  const imageValue = post.acf._dow_featured_image ? true : null
  return (
    <Layout>
      <SEO title="post" location={props.location.pathname} />
      <PostStyled>
        <div className="post-wrapper">
          <div className="post-featured-img">
            {imageValue && (
              <Img
                fluid={
                  post.acf._dow_featured_image.localFile.childImageSharp.fluid
                }
                alt={post.acf._dow_featured_image.alt_text}
              />
            )}
          </div>
          <div className="post-title">
            <h1 dangerouslySetInnerHTML={{ __html: post.title }} />
          </div>
          <div className="post-meta">
            <p>
              <span>{post.date} | </span>
              <span>Written by: {post.acf._dow_author}</span>
            </p>
          </div>
          <WordPressWysiwyg content={post.acf._dow_main_content} />
          <SocialShareButtons url={post.slug} title={post.title} />
        </div>
      </PostStyled>
      <RelatedPosts notThisPost={post.slug} />
    </Layout>
  )
}

export const query = graphql`
  query postQuery($slug: String!) {
    post: wordpressPost(slug: { eq: $slug }) {
      date(formatString: "MMMM D, Y")
      title
      slug
      wordpress_id

      acf {
        _dow_featured_image {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        _dow_author
        _dow_main_content
      }
    }
  }
`

export default post
