/* eslint-disable */
import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

import PostCard from "./PostCard"
import { medWrapper, headlineThree, colors } from "../Utilities"

const getData = graphql`
  query allRelatedPosts {
    posts: allWordpressPost(sort: { order: DESC, fields: date }, limit: 4) {
      edges {
        node {
          date(formatString: "MMMM D, Y")
          title
          slug
          wordpress_id
          acf {
            _dow_author
            _dow_featured_image {
              alt_text
              localFile {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const RelatedPostsStyled = styled.div`
  padding-bottom: 5rem;

  .related-wrapper {
    ${medWrapper};
  }

  .related-title {
    width: 100%;
    margin-left: 2rem;

    h3 {
      ${headlineThree};
      margin: 0;
      color: ${colors.colorAccent};
      text-transform: uppercase;
    }
  }
`

const RelatedPosts = ({ notThisPost }) => {
  const data = useStaticQuery(getData)
  const {
    posts: { edges: posts },
  } = data
  const relatedPosts = posts.filter(post => {
    if (post.node.slug !== notThisPost) return post
  })

  return (
    <RelatedPostsStyled>
      <div className="related-wrapper">
        <div className="related-title">
          <h3>More posts you may also enjoy!</h3>
        </div>
        {relatedPosts.map((post, index) => {
          return <PostCard key={index} post={post.node} index={index} />
        })}
      </div>
    </RelatedPostsStyled>
  )
}

export default RelatedPosts
