import React from "react"
import styled from "styled-components"
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
} from "react-share"

import { colors, headlineThree } from "../Utilities"

const SocialShareButtonsStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 5rem;

  .social-title {
    width: 100%;
    text-align: center;

    p {
      ${headlineThree};
      width: 100%;
      margin: 0;
      color: ${colors.colorAccent};
      text-transform: uppercase;
    }
  }
`

const SocialShareButton = styled.div`
  margin: 0 1rem;

  &:hover {
    cursor: pointer;
  }

  &.share-linkedin {
    margin-right: 0;
  }
`

const SocialShareButtons = ({ url, title }) => {
  return (
    <SocialShareButtonsStyled>
      <div className="social-title">
        <p>Share</p>
      </div>
      <SocialShareButton className="share-twitter">
        <TwitterShareButton style={{ width: "32px" }} url={url} title={title}>
          <TwitterIcon size={32} round={true} />
        </TwitterShareButton>
      </SocialShareButton>
      <SocialShareButton className="share-facebook">
        <FacebookShareButton style={{ width: "32px" }} url={url} quote={title}>
          <FacebookIcon size={32} round={true} />
        </FacebookShareButton>
      </SocialShareButton>
      <SocialShareButton className="share-linkedin">
        <LinkedinShareButton style={{ width: "32px" }} url={url}>
          <LinkedinIcon size={32} round={true} />
        </LinkedinShareButton>
      </SocialShareButton>
    </SocialShareButtonsStyled>
  )
}

export default SocialShareButtons
